import { API, graphqlOperation } from 'aws-amplify';
import { v4 as uuidv4 } from 'uuid';
import { uploadMediaQuery, sendMessageMutation } from '../../graphql/mutations';
import { getConversation, getMessages } from '../../graphql/queries';
import { subscribeToConversation } from '../../graphql/subscriptions';
import { Message, MessageType } from '../../enum/common';
import axios from 'axios';

export const uploadMediaService = async(familyId, conversationId) => {
  let uuid = uuidv4();
  let metadata = {
    mimeType: MessageType.audio,
    tempId: uuid,
    conversationId: conversationId
  };
  const { data: { uploadMedia }, errors } = await API.graphql({
    query: uploadMediaQuery,
    variables: {
      familyId: familyId, type: 'Chat', mediaCount: Message.mediaCount, metadata: metadata
    }
  });
  return uploadMedia
}

export const getRealtimeChat = async(conversationId) => {
  return API.graphql(
    graphqlOperation(
      subscribeToConversation,
      {
        conversationId: conversationId
      }
    )
  )
}

export const sendMessageData = async(conversationId, timestamp, text, type, details) => {
  let uuid = uuidv4();

  const message = {
    sentAt: timestamp,
    tempId: uuid,
    text: text,
    type: type
  };

  if (type == MessageType.audio) {
    delete message['text'];
    message.data = JSON.stringify({
      bucket: details.bucket,
      key: details.key,
      mediaId: details.mediaId
    }) 
  } 

  const { data: { sendMessage }, errors } = await API.graphql({
    query: sendMessageMutation,
    variables: { conversationId: conversationId, message: message }
  });

  return sendMessage;
}

export const getConversations = async(familyId, contactId) => {
  const { data: { getConversation: conversations }, errors } = await API.graphql({
    query: getConversation,
    variables: { familyId: familyId, contactId: contactId }
  });

  if( errors ) {
    return new Error( errors[0].message );
  }

  return conversations;
}

export const getMessagesList = async(conversationId, lastMessageId, limit) => {
  const { data: { getMessages: messages }, errors } = await API.graphql({
    query: getMessages,
    variables: { conversationId: conversationId, sortOrder: Message.order, limit: limit, lastMessageId: lastMessageId }
  });

  if( errors ) {
    return new Error( errors[0].message );
  }

  return messages;
}

export const saveAudioMessage = async( url, file ) => {
  return axios.put( url, file );
}